import React from "react";
import './Col.css'
import { useState } from "react";


// indexSelect ={ indexSelect} setIndexSelected = {setIndexSelected}
const Col = (props)=>{

    return(
    <div className="b">
  <div className="div_col" onClick={()=>{
    props.setIndexSelected(props.index)
  }}>    
    <div style={{display:(props.indexSelect === props.index)?"block":"none",position:"absolute",zIndex:"3",background:"#145",borderRadius:"13px",padding:"5px"}}>
    {`Date:${props.date} 
    
    ${props.highdate.toFixed(2)}${props.unit}`}
</div>
    <div className={"Rectangle1-"+ props.color} ></div>
    <div className={"Rectangle2-"+ props.color} ></div>
    <div className= {"col-"+ props.color} style={{height: props.high}}></div>  


</div>
 
 <div className="date">{!(props.action ===`/energy/site_solar/thirty_days`)? props.date :props.index === 0 || (props.index+1)%3 === 0   ?props.date:""} </div>
 </div>
    )
}
export default Col