import React from 'react'
import "./Button_moth.css"

const Button_moth = (props) => {
  return (
    
    <div className='Button_moth_warop'>
        <div className='Button_moth_warop_inner'>
            <div className={props.class}>
                   {props.operator}
            </div>
            <div>
                <div className={props.class}>
                   
                   <div> ₪{props.amount}</div>
                 </div>
                 <div className='lose_year' >
                    {props.lose_year}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Button_moth