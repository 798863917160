import React from 'react'
import './Rect_green.css'
const Green=(props)=>{
    return(<div className={props.className}>
        <div className='recr_onel'></div>
        <div className='recr_twol'></div>
    </div>)
}



const Rect_green = (props) => {
  return (
    <div>
    <Green className="one"/>
    <Green className="two"/>
    <Green className="three"/>
    <Green className="one1"/>
    <Green className="two2"/>
    <Green className="three3"/>
    <Green className="oner"/>
    <Green className="twor"/>
    <Green className="threer"/>
    <Green className="one1r"/>
    <Green className="two2r"/>
    <Green className="three3r"/>
    </div>
    
  )
}

export default Rect_green