import { useRef ,  useState , useEffect} from "react"
import axiosAuth from "../api/axiosAuth";
import axios from "axios";
import { Link } from "react-router-dom";
import  {useNavigate , useLocation }  from "react-router-dom";
import useAuth from "../hook/useAuth";
import Cookies from "universal-cookie"
import Button_login from "../components/Button_login";
import Button_regester from "../components/Button_regester";
import Image_login from "../components/Image_login";
import Logo from '../components/logo/logo';
const LOGIN_URL = 'authenticate';

const Login = () => 
{
    const userRef = useRef()
    const errRef = useRef()
    const navigate = useNavigate()
    const loaction = useLocation()
    const from = loaction.state?.from?.pathname || "/";
    const cookies = new Cookies();

    const [user ,setUser ] = useState('')
    const [pwd , setPwd] = useState('')
    const [errMsg , setErrMsg] = useState('')

    const { auth ,  setAuth } = useAuth()
    
    useEffect ( () => 
    {
        console.log("auth:" + auth)
        if(auth)
        {
            navigate(from, { replace: true });
        }
    
        userRef.current.focus();
    } , [])
    // from , navigate , auth


    useEffect ( () => 
    {
        setErrMsg('')
    } , [user , pwd])


    const handleSubmit = async(e) => 
    {
        e.preventDefault()
        try
        {
            const response = await axiosAuth.post(LOGIN_URL , {
                'email':user, 'password': pwd
            }
             , {'Access-Control-Allow-Credentials': true});
            
             cookies.set('bezkoder-jwt-refresh' , response?.data['refersh_token'] ,  { path: '/'  , maxAge: 31536000} ,   {httpOnly: true}, {SameSite:"None"})
       
             window.WonderPush = window.WonderPush || [];
             window.WonderPush.push(["init", {
               webKey: "10a66ced5db35c1a5748b395a3e9a1c3d80a1d393b4aba7a7c06bb27c8e887bd",
             }]);
             window.WonderPush = window.WonderPush || [];
             window.WonderPush.push(['setUserId', response?.data['wonderPush_token']]);

            cookies.set('wonderPush_token' , response?.data['wonderPush_token'] ,  { path: '/'  , maxAge: 31536000} ,   {httpOnly: true}, {SameSite:"None"})
          

            axios.defaults.headers.common['Authorization'] = `Bearer ${response?.data['access_token']}`;
            setAuth( response?.data['refersh_token'] )
            setPwd('')
            setUser('')
            navigate(from, { replace: true });
        }
        catch(err)
        {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if(err.response?.status === 404)
            {
                setErrMsg(`${err.response.data}`)
            }
            else {
                setErrMsg('Login Failed')
            }
            errRef.current.focus();
        }
        

    }

  
    return (

        <section className="container-fluid app_container glassmorphism  text-white mt-2 ">
                <p ref={errRef} className = {errMsg ? "alert alert-danger fw-bold mt-3" : "hide"} aria-live = "assertive"> {errMsg}</p>
                <div className="image_login">
                    <Logo className="logo-login"/>
                    <Image_login/>
                    
                    </div>
                <form className="mt-2" onSubmit={handleSubmit}> 
                <div> 
                    {/* <label className="form-label text-light" htmlFor="username"><b>Email</b></label> */}
                    <input className="form-control inputBostrap" type="text"  placeholder="אי-מייל"
                        id = "username"
                        ref={userRef}
                        autoComplete = "off"
                        onChange={(e) => setUser((e.target.value).toLowerCase())}
                        value = {user}
                        required        
                    ></input>
                </div>
                <div> 
                    {/* <label className="form-label text-light" htmlFor="password"><b>Password</b></label> */}
                    <input className="form-control inputBostrap mt-3" type="password" placeholder="סיסמא" 
                        id = "password" 
                        autoComplete = "off"
                        onChange={(e) => setPwd(e.target.value)}
                        value = {pwd}
                        required        
                    ></input>
                </div>
                <div> 
                {/* <button className="btn  mt-4 mb-3 inputBostrap p-0 bor" type="submit"> */}
                <button className="button_login " type="submit"><Button_login/></button>
                </div>
                </form>
                <button className="button_register" ><Link to="/register"> <Button_regester/>
                            </Link></button>

                            <p>
                  <span className="line" style={{marginTop:"10px"}}>
                            <Link to="/forgetPassword"> שכחת סיסמה
                            </Link>
                  </span>
                </p>
                
        </section>
    )
}

export default Login