import React from 'react'
import Site from './Site'

const FeedSites = ({sits , FuninfoSite , setSuccess , setErrMsg }) => {
  return (
    <>
      { 
        sits.map((siteEach) => {
            return <Site FuninfoSite = {FuninfoSite} site = {siteEach} key = {siteEach.id} setSuccess = {setSuccess} setErrMsg = {setErrMsg}></Site>
        })
      }
    </>
  )
}

export default FeedSites