import React from 'react'
import Col from './Col'
import './Chart.css'
import { useState } from 'react' 
const Chart = (props) => {


  // const [arr,SetArr] = useState([])
  


  // const initArray = () =>
  // {
  //   const newarry = []
  //   for(let i = 0 ; i<props.intervalEnergy.lenght ; i++)
  //   {
  //     newarry[i] = false
  //   }
  //   SetArr(newarry)
  // }

  // const clickColarrayindx =(i)=>{
  //   const arrynew = arr.map((item) => {
  //       if(i === item.index)
  //       {
  //         return false
  //       }
  //      return true
  //     })
  //     SetArr(arrynew)
  // }
  
  
  const d =props.intervalEnergy.map((col) => col.y);
  const maxl=Math.max(...d);
  console.log({maxl})
  return (
    <div className='charts'>
    {/* <div className='chart-row' >{maxl}</div> */}
    {props.isLoadingChart? <div>load...</div>
                      :<div  className='charts' style={{position:'relative'}}>
        <div className='chart_unit' style={{position:'absolute',left:"-20px",bottom:"10px",display:"flex",flexDirection:"column",height:Math.round(210),justifyContent: "space-between",    alignItems: "flex-start"}}> 
        <div>{Math.ceil(maxl*1)}{props.unit}</div>
        <div>{Math.ceil(maxl*0.75)}{props.unit}</div>
        <div>{Math.ceil(maxl*0.5)}{props.unit}</div>
        <div>{Math.ceil(maxl*0.25)}{props.unit}</div>
        <div>{(0)}</div>
        </div>
        {props.intervalEnergy.map((bar,index) => (<Col key={index} indexSelect ={ props.indexSelect} setIndexSelected = {props.setIndexSelected} index = {index} high={Math.round((bar.y/maxl)*200) } highdate={bar.y} date={bar.x} color={bar.y/maxl>0.6?"green":"red"} action = {props.action} unit={props.unit}/> ))}
    </div>}
    </div>
  )
}

export default Chart