import React, { useState } from 'react'
import '../css/Header.css'
import { FaKey } from "react-icons/fa";
import { TbCurrencyShekel } from "react-icons/tb";
import { IoMenu } from "react-icons/io5";
import { MdOutlineClose, MdPercent } from "react-icons/md";
// import { MdPercent } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom'
import useAuth from "../hook/useAuth";
import Logo from "../components/logo/logo"
import './profile.css'
import { ReactComponent as Image_login1 } from "../components/siting.svg"
import { ReactComponent as IconProfile } from "../components/IconProfile.svg"
import { AiOutlineSetting, AiOutlinePercentage } from 'react-icons/ai'
import { HiBellAlert } from 'react-icons/hi2'
import { MdToggleOn, MdToggleOff } from 'react-icons/md'
import axios from 'axios'

import Cookies from "universal-cookie"
// import {ReactComponent as Logo}

const HeaderNav = ({ siteid }) => {

  const { auth, setAuth, logout, site_id, setSite_id } = useAuth();

  const [percentDesc, SetPercentDesc] = useState(85.0)
  const [price, setPrice] = useState(0.48)
  const [isNote, setIsNote] = useState(false)
  const [zone, setZone] = useState(1)
  const [zoneInner, setZoneInner] = useState(1)
  const [isLoadiog, SetIsLoading] = useState(true)




  const getSettingSite = async () => {
    await axios.post(`/site_solar/get_custom_Setting`,
      JSON.stringify({ siteId: siteid }),
      {
        headers: { 'Content-Type': 'application/json' }
      }, { 'Access-Control-Allow-Credentials': true }).then(res => {
        if (res.status == 200) {
          // siteId: 154778, pointMap: 1, rate: 20, percentDesc: 70, isOnAlert: true}
          //pointMap
          if (res.data?.pointMap != null && res.data?.subPointMap != null) {
            const pointCurrent = res.data?.subPointMap
            setZoneInner(pointCurrent)
            setZone(res.data?.pointMap)
            // if(pointCurrent <= 3)
            // {
            //   setZone(1)
            // }
            // else{
            //   if(pointCurrent <= 6)
            //   {
            //     setZone(2)
            //   }
            //   else{
            //     if(pointCurrent <= 9)
            //     {
            //       setZone(3)
            //     }
            //     else{
            //       if(pointCurrent <= 12)
            //       {
            //         setZone(4)
            //       }
            //       else{
            //         if(pointCurrent <=15)
            //         {
            //           setZone(5)
            //         }
            //       }
            //     }
            //   }
            // }
          }
          else {
            setZone(0)
            setZoneInner(0)
          }

          //price
          if (res?.data?.rate != null) {
            setPrice(res.data.rate)
          }
          else {
            setPrice(0.48)
          }

          //percentDesc
          if (res?.data?.percentDesc != null) {
            SetPercentDesc(res.data.percentDesc)
          }
          else {
            SetPercentDesc(85)
          }

          //isOnAlert
          if (res?.data?.isOnAlert != null) {
            console.log(res?.data?.isOnAlert)
            setIsNote(res?.data?.isOnAlert)
          }
          else {
            setIsNote(false)
          }

          SetIsLoading(false)
          console.log("get date:" + res.data?.isOnAlert)

        }
      }).catch(err => {
        SetIsLoading(false)
        console.log("false clean today")
      })
  }
  const onSubmitRange = async () => {
    // private Long siteId;
    // private Long pointMap;
    // private Double rate ;
    // private Double percentDesc;
    // private Boolean isOnAlert;
    console.log("Save: percentDesc: " + percentDesc + " , " + " isNote: " + isNote)
    await axios.post(`/site_solar/save_custom_Setting`,
      JSON.stringify({ siteId: siteid, pointMap: zone, subPointMap: zoneInner, rate: price, percentDesc: percentDesc, isOnAlert: isNote }),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }, { 'Access-Control-Allow-Credentials': true })
  }

  const onSubmitIsNote = async () => {
    // private Long siteId;
    // private Long pointMap;
    // private Double rate ;
    // private Double percentDesc;
    // private Boolean isOnAlert;
    console.log("Save: percentDesc: " + percentDesc + " , " + " isNote: " + !isNote)
    await axios.post(`/site_solar/save_custom_Setting`,
      JSON.stringify({ siteId: siteid, pointMap: zone, subPointMap: zoneInner, rate: price, percentDesc: percentDesc, isOnAlert: !isNote }),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }, { 'Access-Control-Allow-Credentials': true })
  }

  const logoutFun = async () => {
    setAuth(null)
    logout()
    // navigate('/login')
  }
  const [show, setShow] = useState(true)


  const changeWidth = (event) => {
    SetPercentDesc(event.target.value);
  }

  return (
    <div className="navbar navbar-expand-lg" style={{ width: "400px" }}>
      <div className='container-fluid  px-2'>
        <a className="navbar-brand text-info" href="#" ><IconProfile /></a>
        <Logo className="logo-nav" />
        <div className="nav-bar">
          <div>
            {show ? <Image_login1 onClick={() => {
              setShow((!show))
              getSettingSite()
            }} /> :
              <button className="navbar-toggler border border-info" > <MdOutlineClose color='white' size={29} onClick={() => setShow((!show))} /></button>}
          </div>
          <div className={show ? 'nev-menu1' : 'nev_menu'} >
            <ul className='nav-menu-items'>
              {
                auth &&
                (<li className="nav_item">
                  <Link className="nav-link text-light" to="/" onClick={() => {
                    // const cookies = new Cookies();
                    // cookies.remove('save_site')
                    setSite_id(null)
                  }}>
                    <b className='li_text'>בחירת מערכת</b>
                    <FaKey />

                  </Link></li>)
              }
              <li className="nav_item">
                <a className="nav-link text-light" href={`/site_setting/${siteid}`}><b className='li_text'>תעריף</b><TbCurrencyShekel /> </a>
              </li>

              <li className="nav_item">
                {
                  (!isNote) ?

                    (<MdToggleOff style={{ fill: "gray", width: "48px", height: "48px", marginRight: "4px" }}
                      onClick={() => {
                        onSubmitIsNote()
                        setIsNote(true)
                      }}></MdToggleOff>)
                    : (
                      <MdToggleOn style={{ fill: "#37C25F", width: "48px", height: "48px", marginRight: "4px" }}
                        onClick={() => {
                          onSubmitIsNote()
                          setIsNote(false)
                        }}></MdToggleOn>)
                }
                <a className="nav-link text-light" href="#"><b className='li_text'>הגדרה של קבלת התראות</b><HiBellAlert /></a>
              </li>

              <li >

                <a className="nav-link text-light nav_item" href="#"><b className='li_text'>אחוז בו הייתי רוצה לקבל התראה</b><AiOutlinePercentage /> </a>
                <input
                  type='range'
                  onChange={changeWidth}
                  onPointerUp={onSubmitRange}
                  min={1}
                  max={100}
                  // marks
                  step={1}
                  value={percentDesc}
                  className='custom-slider'>
                </input>
                <p id="rangeValue">{percentDesc}</p>
              </li>
              {
                auth && (<li className="nav_item">
                  <Link className="nav-link text-light" to="/login" onClick={() => logoutFun()} ><b className='li_text'>התנתקות</b></Link></li>)
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderNav