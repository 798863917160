import { Outlet } from "react-router-dom"

const Layout = () => {
    return (
        <div className="container-fluid px-0 app_container  text-white">
            <Outlet />
        </div>
    )
}

export default Layout