import React from 'react'
import { Navigate, Outlet  , useNavigate } from 'react-router-dom';
import useAuth from '../hook/useAuth';
import Cookies from "universal-cookie"
import axios from 'axios';

const RequiredAuthCopy = () => {

    const { setAuth ,  logout } = useAuth(); 
    const cookies = new Cookies();
    const load = cookies.get('bezkoder-jwt-refresh')? cookies.get('bezkoder-jwt-refresh') : null ; 
    const navigate = useNavigate()

    const comfirmTokenUser = () => {

        if(load)
        {
           console.log("true")
           return true;
        }
        else
         { 
            console.log("false")
            setAuth(null)
            cookies.remove('bezkoder-jwt-refresh')
            axios.defaults.headers.common['Authorization'] = null;
            // navigate('/login', { replace: true });
            return false; 
         }
    }

    return (
        comfirmTokenUser()? <Outlet/> 
             : <Navigate to="/login" replace />)
}

export default RequiredAuthCopy