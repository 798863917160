import React from 'react'
import { useState , useRef , useEffect} from 'react'
import { faCheck , faTimes ,faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosAuth from '../api/axiosAuth';
import  {useNavigate , Link }  from "react-router-dom";
import useAuth from "../hook/useAuth";


const USER_REGEX = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
const REST_PASSWORD = 'user/resetPassword';
const ForgetPassword = () => {
  
    const navigate = useNavigate()
    const userRef = useRef()
    const errRef = useRef()
    const [email , setEmail ] = useState('')
    const [validEmail, setValidEmail] = useState(false);
    const [userFocus, setUserFocus] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const {auth} = useAuth()
    const [action, setAction] = useState(true); 
    const [result , setResult] = useState(false); 

    useEffect(() => 
    {
        if(auth)
        {
            navigate('/');
        }
    } , [])

    useEffect(() => 
    {
        setValidEmail(USER_REGEX.test(email))
        setErrMsg('')
    } , [email])
     
    const handleSubmit = async(e) =>
    {
        e.preventDefault()
        const v1 = USER_REGEX.test(email);
        if (!v1) {
             setErrMsg("קלט לא חוקי");
             return;
            }
        setAction(false)
        await axiosAuth.post(REST_PASSWORD , 
        {'userEmail' : email}).then((res)=> { 
            setErrMsg('בקשה נשלחה במייל לאימות אנחנו חוזרים לדף התחברות בעוד כמה רגעים')
            setResult(true)
        }).catch((err) => {
            setErrMsg('נכשלה בקשה בשליחת המייל לאימות אנחנו חוזרים לדף התחברות בעוד כמה רגעים')
            setResult(false)
        }).finally(()=>
        {
            setTimeout(() => {
                navigate('/login')
            }, 6000);
        })
    }
    return (
    <div className='container-fluid glassmorphism bg-dark text-white mt-2'>
        
        <form className="mt-2" onSubmit={handleSubmit}> 
        <div>
                <label className="form-label text-light" htmlFor="username">
                <h2><b>שכחת סיסמה</b></h2>
                <FontAwesomeIcon icon={faCheck} className={validEmail ? "valid" : "hide"} />
                <FontAwesomeIcon icon={faTimes} className={validEmail || !email ? "hide" : "invalid"} />
                </label>
                <input
                    className="form-control inputBostrap"
                    type="text"
                    id="email"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    aria-invalid={validEmail ? "false" : "true"}
                    aria-describedby="uidnote"
                    onFocus={() => setUserFocus(true)}
                    onBlur={() => setUserFocus(false)}
                    placeholder = "example.com"
                     />
                <p id="uidnote" className={userFocus && email && !validEmail ? "instructions inputBostrap" : "offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />
                               איימל לא תקין
                </p>
        </div>
        <div>
            <button className="btn btn-primary mt-4 mb-3 border border-secondary inputBostrap btnBoxShadon"
                    disabled={(!validEmail || !action) ? true : false}>
                    <strong>אישור</strong>
            </button>
        </div>

        </form>

            <p ref={errRef} className = {errMsg ? result ? "alert alert-success fw-bold inputBostrapforget " :
             "alert alert-danger fw-bold inputBostrapforget " : 
             "hide"} aria-live="assertive">{errMsg}</p>
       
            <span className="line mb-2">
                            <Link to="/login"> חזרה לדף התחברות
                            </Link>
            </span>
    
    </div>
  )
}

export default ForgetPassword