import { useState , useEffect, useRef } from "react";
import { faCheck , faTimes ,faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosAuth from "../api/axiosAuth";
import {useNavigate ,useLocation ,  Link} from 'react-router-dom'
import useAuth from "../hook/useAuth";
import Button_regester_new from "../components/Button_regester_new";
// import Image_login from "../components/Image_login";
import Logo from '../components/logo/logo';
import Image_register from "./registersun.png";
// /^[A-z][A-z0-9-_]{3,23}$/;
const USER_REGEX =  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
// const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const PWD_REGEX = /^.{8,24}$/;
const PHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
const FIRSTNAME_REGEEX = /^(?! *$)[a-zA-Zא-ת.+ '-]+$/; 

const REGISTER_URL = 'registration';


const Register = () =>
{
    //check if we have auth so we pass dircet to home
    const { auth ,  setAuth , logout } = useAuth()
    const navigate = useNavigate() 
    const loaction = useLocation()
    const from = loaction.state?.from?.pathname || "/";

    useEffect ( () => 
    {
        console.log("register")
        if(auth)
        {
            navigate(from, { replace: true });
        }
    } , [])
    //


    const userRef = useRef()
    const errRef = useRef()

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [phone, setPhone] = useState('');
    const [validPhone, setValidPhone] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [validFirstName, setValidFirstName] = useState(false);
    const [firstNameFocus, setFirstNameFocus] = useState(false);



    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => 
    {
        setValidName(USER_REGEX.test(user))
    } , [user])

    useEffect(() => 
    {
        setValidPwd(PWD_REGEX.test(pwd))
        setValidMatch(pwd === matchPwd)
    } , [pwd , matchPwd])

    useEffect(()=> 
    { 
        setValidPhone(PHONE_REGEX.test(phone))
    } , [phone])

    useEffect(()=> 
    { 
        setValidFirstName(FIRSTNAME_REGEEX.test(firstName))
    } , [firstName])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd , phone , firstName])


    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = USER_REGEX.test(user);
        const v2 = PWD_REGEX.test(pwd);
        const v3 = PHONE_REGEX.test(phone)
        const v4 = FIRSTNAME_REGEEX.test(firstName)
        if (!v1 || !v2 || !v3 || !v4) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            await axiosAuth.post(REGISTER_URL,
                JSON.stringify({ email: user, password: pwd , name:firstName ,  phone: phone }),
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            );
            setSuccess(true);
            //clear state and controlled inputs
            //need value attrib on inputs for this
            setUser('');
            setPwd('');
            setMatchPwd('');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if(err.response?.status === 500)
            {
                setErrMsg(err.response.data.message)
            }
            else {
                setErrMsg('Registration Failed')
            }
            errRef.current.focus();
        }
    }

    return (
        <>
        
            {(success) ? (
                <section className="container-fluid mt-2 glassmorphism bg-dark text-white">
                    <h1 className="text-info fw-bold mt-2">
                        {/* Success! 
                        Email verification is waiting for you */}
                        הרשמה הצליחה!
                        האימות באי מייל מחכה לכם
                        </h1>
                    <p className="text-info fw-bold mt-2">
                        <Link to="/login"> 
                        {/* Sign Up */}
                        התחברות
                            </Link>
                    </p>
                </section>
            ) : (
                <section className="container-fluid app_container glassmorphism  text-white mt-2">
                    <p ref={errRef} className = {errMsg ? "alert alert-danger fw-bold mt-3" : "hide"} aria-live="assertive">{errMsg}</p>
                   
                    
                    <div><h2 className="text-info fw-bold mt-2 glassmorphism inputBostrap p-2">
                        {/* <b>Sign up</b> */}
                        <div className="image_login">
                    <Logo className="logo-login"/>
                  
                    <img src={Image_register} width="100%" height="100%" alt="" />
                  
                    </div>
                        </h2></div>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <div className="div_email">
                            <label className="form-label text-light" htmlFor="username">
                                
                                <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                                <FontAwesomeIcon icon={faTimes} className={validName || !user ? "hide" : "invalid"} />
                            </label>
                            <input
                                className= "form-control inputBostrap"
                                placeholder="אי-מייל"
                                type="text"
                                id="username"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setUser((e.target.value).toLowerCase())}
                                value={user}
                                required
                                aria-invalid={validName ? "false" : "true"}
                                aria-describedby="uidnote"
                                onFocus={() => setUserFocus(true)}
                                onBlur={() => setUserFocus(false)}
                                
                            />
                            </div>
                            <p id="uidnote" className={userFocus && user && !validName ? "instructions inputBostrap" : "offscreen"}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                {/* Invalid Email */}
                                האיימל לא תקין
                            </p>
                        </div>

                        <div>
                            <div className="div_email">
                            <label className="form-label text-light" htmlFor="phone">
                                {/* <b>Phone Number</b> */}
                                <FontAwesomeIcon icon={faCheck} className={validPhone ? "valid" : "hide"} />
                                <FontAwesomeIcon icon={faTimes} className={validPhone || !phone ? "hide" : "invalid"} />
                            </label>
                            <input
                                placeholder="טלפון"
                                className="form-control inputBostrap"
                                type="text"
                                id="phone"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                                required
                                aria-invalid={validPhone ? "false" : "true"}
                                aria-describedby="uidnote"
                                onFocus={() => setPhoneFocus(true)}
                                onBlur={() => setPhoneFocus(false)}
                            />
                            </div>
                            <p id="uidnote" className={phoneFocus && phone && !validPhone ? "instructions inputBostrap" : "offscreen"}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                {/* Invalid Phone number */}
                                מספר פלאפון לא תקין
                            </p>
                        </div>

                        <div>
                        <div className="div_email">
                            <label className="form-label text-light" htmlFor="firstname">
                                {/* <b>First Name</b> */}
                                <FontAwesomeIcon icon={faCheck} className={validFirstName ? "valid" : "hide"} />
                                <FontAwesomeIcon icon={faTimes} className={validFirstName || !firstName ? "hide" : "invalid"} />
                            </label>
                            <input
                                placeholder="שם פרטי"
                                className="form-control inputBostrap"
                                type="text"
                                id="firstname"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                required
                                aria-invalid={validFirstName ? "false" : "true"}
                                aria-describedby="uidnote"
                                onFocus={() => setFirstNameFocus(true)}
                                onBlur={() => setFirstNameFocus(false)}
                            />
                            </div>
                            <p id="uidnote" className={firstNameFocus && firstName && !validFirstName ? "instructions inputBostrap" : "offscreen"}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                {/* Invalid First Name */}
                                שם פרטי לא תקין
                            </p>
                        </div>

                        <div>
                        <div className="div_email">
                            <label className="form-label text-light" htmlFor="password">
                                {/* <b>Password</b> */}
                                <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                                <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                            </label>
                            <input
                                placeholder="סיסמה"
                                className="form-control inputBostrap"
                                type="password"
                                id="password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                                aria-invalid={validPwd ? "false" : "true"}
                                aria-describedby="pwdnote"
                                onFocus={() => setPwdFocus(true)}
                                onBlur={() => setPwdFocus(false)}
                            />
                            </div>
                            <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions inputBostrap" : "offscreen"}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                .צריך ב8-24 תווים<br />
                                 {/* <FontAwesomeIcon icon={faInfoCircle} />
                                8 to 24 characters.<br />
                                Must include uppercase and lowercase letters, a number and a special character.<br />
                                Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span> */}
                            </p>
                        </div>

                        <div>
                        <div className="div_email">
                            <label className="form-label text-light" htmlFor="confirm_pwd">
                                {/* <b>Confirm Password</b> */}
                                <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                                <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                            </label>
                            <input
                                placeholder="אימות סיסמה"
                                className="form-control inputBostrap"
                                type="password"
                                id="confirm_pwd"
                                onChange={(e) => setMatchPwd(e.target.value)}
                                value={matchPwd}
                                required
                                aria-invalid={validMatch ? "false" : "true"}
                                aria-describedby="confirmnote"
                                onFocus={() => setMatchFocus(true)}
                                onBlur={() => setMatchFocus(false)}
                            />
                             </div>
                            <p id="confirmnote" className={matchFocus && !validMatch ? "instructions inputBostrap" : "offscreen"}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                {/* Must match the first password input field. */}
                                האימות סיסמה לא תואם
                            </p>    
                        </div>

                        <div>
                            <button className="button_register_new"
                              disabled={!validName || !validPwd || !validMatch || !validPhone || !validFirstName? true : false}>
                                {/* <strong>Sign Up</strong> */}
                                <Button_regester_new/>
                            </button>
                        </div>
                    </form>
                    <p>
                        {/* Already registered? */}
                        משתמש קיים
                        <br />
                        <span className="line">
                            <Link to="/login"> 
                            {/* Sign In  */}
                            התחברות
                            </Link>
                        </span>
                    </p>
                </section>
            )}
        </>
    )
}

export default Register;