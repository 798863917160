import React from 'react'
// import Feature from "../components/feature/Feature";
import TEST1 from '../components/TEST1/TEST1';
import "./CleanSys.css"
import Button_moth from '../components/Button_moth';

const CleanSys = (props) => {
  return (
    <div className='container-fluid '>

      {/* <div style={{'height':"250px" }} className='circleGlassmorphism p-2 mt-5'> */}
      <div style={{ width: "100%", height: "100%" }}>
        <div style={{ width: "100%", height: "100%" }}>
          {/* <h4 className='fw-noarml text-light'><b>רמת יעלות נוכחית</b></h4> */}
          {/* <Feature/> */}
          <TEST1 exploit={Math.round(props.exploit)} />
        </div>

        <div className='under1'>
          <Button_moth operator="-" amount={props.lostyear} lose_year="הפסד שנתי צפוי" class="amount" />
          <Button_moth operator="+" amount={props.sum_month} lose_year="הכנסה חודשית" class="amount1" />
        </div>

      </div>
      <div className="claner-warp">
        <div className='claner-warp1'>
          <div className="claner-warp-inner1">
            <div className={!props.isCleanToday ? "claner-warp-inner" : "claner-warp-inner-disable"} onClick={!props.isCleanToday ? props.ClickCleanToday : () => { }}>
              שטפתי את המערכת
            </div>
          </div>
        </div>
      </div>



    </div>
  )
}

export default CleanSys