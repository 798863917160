import React from "react";
 
const Popup = props => {
  return (
    <div className="container-fluid bg-dark text-white">
        {props.content}
    </div>
  );
};
 
export default Popup;