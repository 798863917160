import React, { useState , useEffect } from 'react'
import "./site_setting.css"
// import { ReactComponent as Map } from './map.svg'
import Map from './Map'
import {useParams , useNavigate} from 'react-router-dom'
import axios from 'axios'
import { MdToggleOn,MdToggleOff } from 'react-icons/md'


const Site_setting = () => {

  const [price , setPrice] = useState(0.48)
  const [isNote , setIsNote] = useState(false)
  const [zone,setZone]= useState(1)
  const [zoneInner,setZoneInner]= useState(1)
  const [percentDesc , SetPercentDesc] = useState(85.0) 
  const [isLoadiog , SetIsLoading ] = useState(true)
  // const [width, setWidth] = useState(100);
  const {id} = useParams()
  const [success , SetSuccess] = useState(false)
  const [error , SetError] = useState(false)
  const navigate =  useNavigate()
  useEffect(()=>
  {
    SetSuccess(false)
    SetError(false)
  } , [price ,isNote ,zone ,  zoneInner , percentDesc])

  useEffect(()=>{
    console.log(zone)
  } , [zone])

  const changeZone = (zoneNew) => 
  {
    if(zoneNew != zone)
    {
      setZoneInner(0)
    }
    setZone(zoneNew)
    
  }


  
//  const changeWidth = (event) => {
//   SetPercentDesc(event.target.value);
//   }
  const getSettingSite = async() => 
  {
    await axios.post(`/site_solar/get_custom_Setting` , 
      JSON.stringify({siteId: id }) , 
     {
      headers: { 'Content-Type': 'application/json'}
     } , {'Access-Control-Allow-Credentials': true}).then(res => { 
        if(res.status == 200)
        {
          // siteId: 154778, pointMap: 1, rate: 20, percentDesc: 70, isOnAlert: true}
          //pointMap
          if(res.data?.pointMap != null && res.data?.subPointMap != null)
          {
            const pointCurrent = res.data?.subPointMap
            setZoneInner(pointCurrent)
            setZone(res.data?.pointMap)

            
          }
          else{
            setZone(0) 
            setZoneInner(0)
          }

          //price
          if(res?.data?.rate != null)
          {
            setPrice(res.data.rate)
          }
          else{
            setPrice(0.48)
          }

          //percentDesc
          if(res?.data?.percentDesc != null)
          {
            SetPercentDesc(res.data.percentDesc)
          }
          else{
            SetPercentDesc(85)
          }

          //isOnAlert
          if(res?.data?.isOnAlert != null)
          {
            setIsNote(res.data.isOnAlert)
          }
          else{
            setIsNote(true)
          }

          SetIsLoading(false)
          console.log(res?.data)
         
        }
        else{
          setZone(0) 
          setZoneInner(0)
        }
      }).catch(err => 
        {
          SetIsLoading(false)
          console.log("false clean today")
        })
  }

  useEffect(() => {

    getSettingSite()


  }  , [])

  const onSubmit = async(e)=> 
  {
    e.preventDefault();
    if(price > 0)
    {
      if(zoneInner > 0)
      {
        // private Long siteId;
        // private Long pointMap;
        // private Double rate ;
        // private Double percentDesc;
        // private Boolean isOnAlert;

        await axios.post(`/site_solar/save_custom_Setting` , 
        JSON.stringify({siteId:id , pointMap: zone , subPointMap: zoneInner , rate: price , percentDesc: percentDesc ,isOnAlert:isNote }) , 
        { 
         headers: {
                    'Content-Type': 'application/json'
                  }
        }, {'Access-Control-Allow-Credentials': true})
          .then((res) => {
            SetSuccess(true)
            SetError(false)
            setTimeout(() => {  navigate('/') } , 2000);
          })
          .catch( (err) => 
              {
                SetError(true)
                SetSuccess(false)
              })
      }
      else{
        SetError(true)
        SetSuccess(false)
      }
    }
    else{
      SetError(true)
      SetSuccess(false)
    }
    
  }

  return (
     <div style={{display: !isLoadiog?"":"none"}}>
      <div className='backgroundPage' >
        <h2 className='title'>פרטי המערכת</h2>
        <form className='form_setting' onSubmit = {onSubmit}>
          <label className='price'>
                  תעריף המערכת    
          </label>

          <input
                                  className='input_price'
                                  placeholder='0.48'
                                  type="number"
                                  step={0.01}
                                  id="price"
                                  autoComplete="off"
                                  onChange={(e) => setPrice((e.target.value))}
                                  value={price}
                                  required
                              />

          {/* <input className='input_price' type={'number'}  placeholder='0.48'>
              
          </input> */}
          
          <div className='title_map'>
          בחר איזור מערכת על המפה 
          
          </div>
          <div>
          <Map changeZone ={changeZone} zone ={zone} setZone = {setZone} zoneInner = {zoneInner} setZoneInner = {setZoneInner} />
          </div>

          
          
          <button disabled = {success} className='change_setting' >שנה הגדרות</button>
          </form>

          {success ?<p className= "alert alert-success mt-2 mb-1"><b>הגדרות שונה בהצלחה</b></p> : null}
          {error ? <p className= "alert alert-danger mt-2 mb-1"><b>לא הצליח להשתנות</b></p> : null}
          
      </div>
    </div>
  )
}

export default Site_setting