import React from "react";
import './possibillty.css';
// import Icon from './icon_monn';

const Possibillty = (props)=>{
    return(
        <div className="matan">
            <svg className="barbar" width="386" height="73" viewBox="0 0 386 73" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_0_1)">
                <path d="M385.679 33.3122V26.3937C385.679 11.937 373.96 0.217529 359.503 0.217529L38.9865 0.217529C24.5298 0.217529 12.8103 11.937 12.8103 26.3937V33.3122C12.8103 47.7688 24.5298 59.4883 38.9865 59.4883L359.503 59.4883C373.96 59.4883 385.679 47.7688 385.679 33.3122Z" fill="url(#paint0_linear_0_1)"/>
                </g>
                <path d="M358.386 4.16412H40.1118C27.2745 4.16412 16.8679 14.5708 16.8679 27.408V32.2977C16.8679 45.135 27.2745 55.5416 40.1118 55.5416H358.386C371.223 55.5416 381.63 45.135 381.63 32.2977V27.408C381.63 14.5708 371.223 4.16412 358.386 4.16412Z" fill="url(#paint1_linear_0_1)"/>
                <g clipPath="url(#clip0_0_1)">
                <path d="M275.015 43.0801V17.9199" stroke="#353535" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M274.493 43.0801V17.9199" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <path d="M132 43.0801V17.9199" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <defs>
                <filter id="filter0_d_0_1" x="0.193774" y="0.217529" width="385.486" height="71.8873" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="-6.30826" dy="6.30826"/>
                <feGaussianBlur stdDeviation="3.15413"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_0_1" x1="200.117" y1="-2.73055" x2="198.381" y2="62.1748" gradientUnits="userSpaceOnUse">
                <stop stopColor="#353535"/>
                <stop offset="1" stopColor="#161616"/>
                </linearGradient>
                <linearGradient id="paint1_linear_0_1" x1="200.014" y1="1.08924" x2="198.484" y2="58.3867" gradientUnits="userSpaceOnUse">
                <stop stopColor="#161616"/>
                <stop offset="1" stopColor="#353535"/>
                </linearGradient>
                <clipPath id="clip0_0_1">
                <rect width="2" height="27" fill="white" transform="translate(274 17)"/>
                </clipPath>
                </defs>
                </svg>
                {props.children}
                </div>


    )
}
export default Possibillty