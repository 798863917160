import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { format } from 'date-fns'
import Possibillty from './Possibillty';



const ButtonsGroupRenew = ({action , setAction ,setDateChooiesString }) => {

  const SITE_INTERVAL_THRITY_DAYS_URL = `/energy/site_solar/thirty_days`
  const SITE_INTERVAL_TWELVE_MONTHS_URL = `/energy/site_solar/twelve_months`
  const SITE_INTERVAL_ALL_YEARS_URL = `/energy/site_solar/all_years`
  
  //for a click on a group buttons: daily , mothly , yearly
  const handleClick = (e) => 
  {
    
    const chooies = e.target.value 
    console.log(chooies)
    if(chooies !== undefined )
    {
      const date = format(new Date(), 'yyyy-MM-dd')
      setDateChooiesString(date)
      setAction(chooies)
    }
  }
  
  return (
    <div className="container text-white mb-4 mt-2">
      <Possibillty>
          <ToggleButtonGroup className='all_group_radios'  type="radio" name="options" defaultValue={action} onClick = {handleClick}>
          <ToggleButton className='daily'  variant = "outline-dark" id="tbg-radio-1" value={SITE_INTERVAL_THRITY_DAYS_URL}>
            <b>יומי</b>
          </ToggleButton>
          <ToggleButton className='monthly' variant = "outline-dark"  id="tbg-radio-2" value={SITE_INTERVAL_TWELVE_MONTHS_URL}>
           <b>חודשי</b> 
          </ToggleButton>
          <ToggleButton className='yearly' variant = "outline-dark" id="tbg-radio-3" value={SITE_INTERVAL_ALL_YEARS_URL}>
            <b>שנתי</b>
          </ToggleButton>
      </ToggleButtonGroup>
      </Possibillty>
    </div>
  )
}

export default ButtonsGroupRenew

//style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} style={{ position: 'relative' }}