import {createContext , useEffect, useState} from "react"
import Cookies from "universal-cookie"
import axios from "axios"

const AuthContext = createContext({})

export const AuthProvider = ({ children }) =>
{
    const cookies = new Cookies();
    const load = cookies.get('bezkoder-jwt-refresh'); 
    const [auth , setAuth]  = useState(load ? cookies.get('bezkoder-jwt-refresh') : null)


    const loadSite = cookies.get('save_site')
    const [site_id , setSite_id] = useState(loadSite ? cookies.get('save_site'): null)


    useEffect(()=> 
    {
        if(site_id == null)
        {
            cookies.remove('save_site')
        }
        else{
            cookies.set('save_site' , site_id,  { path: '/'  , maxAge: 31536000} ,   {httpOnly: true}, {SameSite:"None"})
        }
    } , [site_id])

    const login = (loginAuth) => 
    {
        // cookies.set('bezkoder-jwt-refresh' , JSON.stringify(loginAuth['refersh_token']) ,  { path: '/'  , maxAge: 31536000})
        // axios.defaults.headers.common['Authorization'] = `Bearer ${loginAuth['access_token']}`;
      
    }
    const logout = async() => 
    {

        setSite_id(null)
        // cookies.remove('save_site')

        const refershToken = cookies.get('bezkoder-jwt-refresh')
        console.log(refershToken)
        const {data} = await axios.post('signout' , {"token":refershToken});
        cookies.remove('bezkoder-jwt-refresh')
        axios.defaults.headers.common['Authorization'] = null;
        console.log(data?.message)
        cookies.remove('wonderPush_token')
    }

    return (
        <AuthContext.Provider value={{auth , setAuth , login , logout , site_id , setSite_id}}>
            {children} 
        </AuthContext.Provider>
    )
}
export default AuthContext;



// var today = new Date();
            // var nextSession = new Date() 
            // nextSession.setDate(today.getDate() + 100 );
            // cookies.set('jwt_authorization' , JSON.stringify(auth) ,  { path: '/'  , maxAge: 31536000} , 
            // {
            //     expires: nextSession
            // }
            // )