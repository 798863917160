import axios from "axios";
import Cookies from "universal-cookie";
axios.defaults.baseURL = 'https://plp-alb.greenbrush.org/api/v1/';

let refresh = false;
const cookie = new Cookies()

axios.interceptors.response.use(
    resp =>
    { 
        refresh = false
        console.log("Good");
        return resp
    } ,
    async error => {
        console.log("Error")
        if (error.response.status === 401 && !refresh) {
            refresh = true;
            axios.defaults.headers.common['Authorization'] = null;
            const refershToken = cookie.get('bezkoder-jwt-refresh'); 
            const responses = await axios.post('refreshtoken' , {"token":refershToken});
            // const responses = await axios.get('refreshtoken',  { withCredentials: true });

            if (responses.status === 200) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${responses.data['access_token']}`;
                error.config.headers['Authorization'] = `Bearer ${responses.data['access_token']}`
                return axios(error.config);
            }
            else{
                axios.defaults.headers.common['Authorization'] = null;
                cookie.remove('bezkoder-jwt-refresh')
                window.location.href = '/';
                refresh = true;
            }
        }
        refresh = false;
        console.log(error)
        return error;
    });