import React from 'react'
import { useState  , useEffect} from 'react'
import { faCheck , faTimes ,faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosAuth from '../api/axiosAuth';
import  {useNavigate , useParams  }  from "react-router-dom";

const PWD_REGEX = /^.{8,24}$/;
const UPDATE_PASSWORD = 'user/savePassword';
const CHECK_TOKEN = 'user/check_token';

const UpdatePassword = () => {

    const navigate = useNavigate()
    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);
    const [errMsg , setErrMsg] = useState('')
    const [action , setAction] = useState(true)
    const [result , setResult] = useState(false)

    const {token} = useParams()

    useEffect(() => {
        if(token != null)
        {
          axiosAuth.post(CHECK_TOKEN , {'token':token}).catch(
            (err) => {
                navigate('/login');
            }
          )        
        }
    } , [])

    useEffect(() => 
    {
        setValidPwd(PWD_REGEX.test(pwd))
        setValidMatch(pwd === matchPwd)
    } , [pwd , matchPwd])

    const handleSubmit = async(e) => {
        
        console.log(token)
        e.preventDefault()
        const v1 = PWD_REGEX.test(pwd);
        if (!v1) {
             return;
        }
        setAction(false)
        axiosAuth.post(UPDATE_PASSWORD , {'token' : token , 'newPassword' : pwd}).then((res)=> { 
          setErrMsg('העדכון סיסמה הצליח בהצלחה אנחנו חוזרים לדף התחברות בעוד כמה רגעים')
          setResult(true)
        }).catch((err) => {
          setErrMsg('העדכון סיסמה נכשל תנסה שוב דרך המייל להכנס לקישור')
          setResult(false)
        }).finally(() => 
        {
          setTimeout(() => {
            navigate('/login')
          }, 6000);
        })
    }
  return (
    <div className='container-fluid glassmorphism bg-dark text-white mt-2'>
    <form className="mt-2" onSubmit={handleSubmit}> 
    <div>
         <h2><b>עדכון סיסמה</b></h2>
        <label className="form-label text-light" htmlFor="password">
         <b>סיסמה חדשה</b>
         <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
         <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
        </label>
        <input
          className="form-control inputBostrap"
          type="password"
          id="password"
          onChange={(e) => setPwd(e.target.value)}
          value={pwd}
          required
          aria-invalid={validPwd ? "false" : "true"}
          aria-describedby="pwdnote"
          onFocus={() => setPwdFocus(true)}
          onBlur={() => setPwdFocus(false)}
          />
        <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions inputBostrap" : "offscreen"}>
          <FontAwesomeIcon icon={faInfoCircle} />
          8 - 24 תווים<br />
        </p>
    </div>

    <div>
        <label className="form-label text-light" htmlFor="confirm_pwd">
          <b>אימות סיסמה</b>
          <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
          <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
        </label>
          <input
            className="form-control inputBostrap"
            type="password"
            id="confirm_pwd"
            onChange={(e) => setMatchPwd(e.target.value)}
            value={matchPwd}
            required
            aria-invalid={validMatch ? "false" : "true"}
            aria-describedby="confirmnote"
            onFocus={() => setMatchFocus(true)}
            onBlur={() => setMatchFocus(false)}
            />
            <p id="confirmnote" className={matchFocus && !validMatch ? "instructions inputBostrap" : "offscreen"}>
              <FontAwesomeIcon icon={faInfoCircle} />
               אימות סיסמה חייב להיות זהה לסיסמה
            </p>    
    </div>

    <div>
        <button className="btn btn-primary mt-4 mb-3 border border-secondary inputBostrap btnBoxShadon"
                disabled={( !validPwd || !validMatch || !action ) ? true : false}>
                <strong>עדכן סיסמה</strong>
        </button>
    </div>
    </form>
    <p className = {errMsg ? result ? "alert alert-success fw-bold inputBostrapforget " :
             "alert alert-danger fw-bold inputBostrapforget " : 
             "hide"} aria-live="assertive">{errMsg}</p>

</div>
  )
}

export default UpdatePassword