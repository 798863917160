import React  , { Component } from 'react'
// import CanvasJSReact from '../canvas/canvasjs.react'
import Chart from './Chart'
// var CanvasJS = CanvasJSReact.CanvasJS;
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const SITE_INTERVAL_HOURLY_URL = `/energy/site_solar/hourly`
const SITE_INTERVAL_DAILY_URL = `/energy/site_solar/daily`
const SITE_INTERVAL_MONTHLY_URL = `/energy/site_solar/monthly`

class IntervalSite extends Component {

	

	constructor(props) {
		super(props);
	}
	
	
	render() {	

		const {intervalEnergy , title , action , sum , unit , foramteX , foramtData,indexSelect,setIndexSelected} = this.props;
		// const options = { 
		// 	subtitles: [{
		// 		borderThickness:2,
		// 		borderColor:'white' ,  
		// 		padding:5, 
		// 		fontSize:15 , 
		// 		fontStyle:'normal' , 
		// 		fontWeight:'lighter' , 
		// 		text: `Production: ${sum < 1000 ? (parseFloat(sum.toFixed(2)))+'KWh' : (parseFloat((sum/1000).toFixed(2)))+'MWh'}`
		// 	}],
		// 	axisY: {
			
		// 		gridThickness: 0 ,
		// 		lineColor: "rgba(0, 0, 0, 0.02)",
		// 		tickColor: "rgba(0, 0, 0, 0.02)"  , 
		// 		gridColor: "rgba(0, 0, 0, 0.01)" , 

				
		// 		valueFormatString: `##0.##${unit}`,
		// 		includeZero: true,
		// 		crosshair: {
		// 			enabled: true,
		// 			snapToDataPoint: true,
		// 			labelFormatter: function(e) {
		// 				return CanvasJS.formatNumber(e.value, "##0.##") + unit;
		// 			}
		// 		}
		// 	} , 
		// 	axisX: {
		// 		// titleFontColor: "#6D78AD",
		// 		gridThickness: 0  , 
		// 		lineColor: "rgba(0, 0, 0, 0.1)",
		// 		tickColor: "rgba(0, 0, 0, 0.1)" , 
		// 		gridColor: "rgba(0, 0, 0, 0.01)" , 


		// 		valueFormatString: foramteX ,
		// 		includeZero: true,
		// 		crosshair: {
		// 			enabled: true,
		// 			snapToDataPoint: true
		// 		}
		// 	}, 
		// 	toolTip: {
		// 		shared: true
		// 	}, 
		// 	legend: {
		// 		cursor: "pointer",
		// 		itemclick: this.toggleDataSeries
		// 	} , 
		// 	animationEnabled: true,
		// 	// exportEnabled: true,
		// 	theme: "dark1", //"light1", "dark1", "dark2" , "light2"
		// 	title:{
		// 		text: title
		// 	},
		// 	height:300 , 
		// 	data: [{
		// 		name: "Production",
		// 		xValueFormatString: foramtData ,
		// 		type: (action === "/energy/site_solar/hourly" ? "area" : "column"), //change type to bar, line, area, pie, column etc 
		// 		// indexLabel: "{y}", //Shows y value on all Data Points
		// 		indexLabelFontColor: "#5A5757",
		// 		indexLabelPlacement: "outside",
		// 		dataPoints: intervalEnergy , 
		// 		yValueFormatString: `#,##0.##${unit}`,
		// 		color: "#13E079" 
		// 	}] , 
		// 	backgroundColor: "rgba(0, 0, 0, 0.1)"
		// }
						
		return (
		<div>
			{/* <CanvasJSChart options = {options}
				onRef={ref => this.chart = ref}
			/> */}
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		
			<Chart indexSelect = {indexSelect} setIndexSelected = {setIndexSelected} intervalEnergy = {intervalEnergy} foramteX={foramteX} unit={unit} action={action}></Chart>
		</div>
		);
	}
} 		
export default IntervalSite; 
