import './App.css';
import Login from './pages/Login';
import Register from './pages/Register';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home'
import RequiredAuthCopy from './pages/RequiredAuthCopy'
import Layout from './pages/Layout'
import HeaderNav from './pages/HeaderNav';
import SiteInfoRenew from './pages/SiteInfoRenew';
import Site_setting from './pages/Site_setting';
import ForgetPassword from './pages/ForgetPassword';
import UpdatePassword from './pages/UpdatePassword';
// import Navbar from './components/Navbar';


// import Logo from './components/logo/logo';

function App() {
  return (
    <div className="container-fluid app_container px-0" align="center">
      <BrowserRouter>
        {/* <HeaderNav/> */}
        {/* <Nav /> */}
        {/* <Logo/> */}
        <Routes>
          {/* <Route path ='/r' element={<Navbar/>}/> */}

          <Route path="/" element={<Layout />}>
            <Route path='login' element={<Login />} />
            <Route path='register' element={<Register />} />
            <Route path='forgetPassword' element={<ForgetPassword />} />
            <Route path='updatePassword/:token' element={<UpdatePassword />} />

            <Route element={<RequiredAuthCopy />}>
              <Route path='/' element={<Home />} />
              <Route path='/site/:id' element={<SiteInfoRenew />} />
              <Route path='/site_setting/:id' element={<Site_setting />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
