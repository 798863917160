import { Link } from 'react-router-dom'
import { useState , useContext } from 'react';
import  {useNavigate}  from "react-router-dom";
import Popup from './Popup';
import axios from 'axios'
import AuthContext from "../context/AuthProvider"
import {AiOutlineSetting} from 'react-icons/ai'
import Cookies from "universal-cookie"

const Site = ({site , FuninfoSite , setSuccess , setErrMsg}) => {


  const { auth , setAuth , logout ,site_id , setSite_id} = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const logoutFun = async () => {
    setAuth(null)
    logout()
    navigate('/login')
  }

  const disconnection = async (solarId , solarName) =>
  {
    await axios.post('ownersite/delete_belong' , 
    JSON.stringify({siteId: solarId , siteName:solarName ,  company: 'solaredge'}) , 
    { 
     headers: {
                'Content-Type': 'application/json'
              }
    }, {'Access-Control-Allow-Credentials': true})
      .then((res) => {setSuccess(res?.data)
                      setErrMsg('')
                      FuninfoSite()
      })
      .catch( (err) => 
          {
            setErrMsg(err.response?.data)
            setSuccess('')
            if(err.response?.status === 401)
            {
              logoutFun()
            }
          })
  }

  return (
 
    <div className='glassmorphism mt-4'>
    <div className="card text-center bg-dark text-white border border-dark ">
      <div className="card-header pt-3">
       <h5><Link className="nav-link text-light" to={`/site/${site.id}`}
       onClick = {()=>{
        setSite_id(site.id)
      }}> 
          <strong>{site.name}</strong>
       </Link></h5>
      </div>
      <div className="card-body">
        <h5 className="card-title">{`${site.location?.country}, ${site.location?.city}  ${site.location?.address}`}<br/></h5>
        <p className="card-text">
            </p>
            
            {isOpen && <Popup
              content={
              <>
                <p>
                  <b>Peak Power:</b> {site.peakPower}<br/>
                  <b>Last Update Time:</b> {site.lastUpdateTime}<br/>
                  <b>installation Date:</b> {site.installationDate}<br/>
                  <b>facturer Name:</b> {site.primaryModule?.manufacturerName}<br/>
                  <b>model Name:</b> {site.primaryModule?.modelName}<br/> 
                  <b>maximum Power:</b> {site.primaryModule?.maximumPower ? site.primaryModule?.maximumPower  : 0}<br/> 
                  <b>temperature Coef:</b> {site.primaryModule?.temperatureCoef? site.primaryModule?.temperatureCoef :0}<br/> 
                  <b>alert Quantity:</b> {site.alertQuantity}<br/> 
                </p>
              </>}
              handleClose={togglePopup}
         />}

          <Link to={`/site/${site.id}`} onClick = {()=>{
            setSite_id(site.id)
          }}>
                <button  className="btn btn-success m-1 border border-secondary">View Solar</button>
          </Link>
          
          <button type="button"
            onClick={togglePopup}
            className="btn btn-primary m-1 border border-secondary">More properties
          </button>
      
          <button className="btn btn-danger m-1 border border-secondary" onClick={() => disconnection(site.id , site.name)}>
            Disconnection
          </button>
         
      </div>
      <div className="card-footer p-2" style={{display: "flex",justifyContent:"center"}}>
        <h5 className={site.status === 'Active'? 'text-success m-1' : 'text-danger m-1' }> 
              <strong>{site.status}</strong>
        </h5>
         <h5><Link className="nav-link text-light" to={`/site_setting/${site.id}`} style={{position: "absolute", right:"30px"}}> 
          <AiOutlineSetting style={{width:"30px",height:"30px"}}/>
       </Link></h5>
      </div>
   </div>    
    </div>
  )
}

export default Site
