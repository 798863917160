import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import useAuth from '../hook/useAuth'
import IntervalSite from './IntervalSite'
import { format, parse } from 'date-fns'
import ButtonsGroupRenew from './ButtonsGroupRenew'
import CleanSys from './CleanSys'
import './SiteInfoRenew.css'
import HeaderNav from './HeaderNav'
import Rect_green from '../components/Rect_green'
import GridLoader from "react-spinners/GridLoader";



const SiteInfoRenew = () => {

  const SITE_INTERVAL_THRITY_DAYS_URL = `/energy/site_solar/thirty_days`
  const SITE_INTERVAL_TWELVE_MONTHS_URL = `/energy/site_solar/twelve_months`
  const SITE_INTERVAL_ALL_YEARS_URL = `/energy/site_solar/all_years`
  const { id } = useParams()
  const [site, setSite] = useState({})
  const [intervalEnergy, setIntervalEnergy] = useState([])
  const [action, setAction] = useState(SITE_INTERVAL_THRITY_DAYS_URL)
  const [dateChooiesString, setDateChooiesString] = useState(format(new Date(), 'yyyy-MM-dd'))
  const [sum, setSum] = useState(0)
  const [lostyear, setLostyear] = useState(0)
  const [sum1, setSum1] = useState(0)
  const [widthpx, setwidthpx] = useState('5px')

  //
  const [unit, setUnit] = useState('KWh')
  const [foramteX, setForamteX] = useState('DD MMM')
  const [foramtData, setForamtData] = useState('DD MMM YYYY ')
  //
  const [exploit, setExploit] = useState(0)
  const [isCleanToday, setIsCleanToday] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingChart, setIsLoadingChart] = useState(true)

  const navigate = useNavigate()
  const SITE_URL = `/site_solar/${id}`
  const { auth, setSite_id } = useAuth()

  const [rate, setRate] = useState(0.48)

  const [indexSelect, setIndexSelected] = useState(-1)

  // useEffect(()=>
  // {
  //   console.log(id)
  //   getCleanToday()
  //   getExploit()
  //   getRate()
  // } ,[id])


  const ClickCleanToday = async (e) => {
    e.preventDefault()

    await axios.post(`/site_solar/click_clean_site`,
      JSON.stringify({ siteId: id }),
      {
        headers: { 'Content-Type': 'application/json' }
      }, { 'Access-Control-Allow-Credentials': true }).then(res => {
        if (res.status == 200) {
          getCleanToday()
        }
      }).catch(err => {
        setIsCleanToday(false)
      })
  }




  const getRate = async () => {
    await axios.post(`/site_solar/get_custom_Setting`,
      JSON.stringify({ siteId: id }),
      {
        headers: { 'Content-Type': 'application/json' }
      }, { 'Access-Control-Allow-Credentials': true }).then(res => {
        if (res.status == 200) {
          // siteId: 154778, pointMap: 1, rate: 20, percentDesc: 70, isOnAlert: true}
          //price
          if (res?.data?.rate != null) {
            setRate(res.data.rate)
          }
          else {
            setRate(0.48)
          }
        }
        else {
          setRate(0.48)
        }
      }).catch(err => {
        setRate(0.48)
      })
  }

  const getCleanToday = async () => {
    await axios.post(`/site_solar/get_clean_site_today`,
      JSON.stringify({ siteId: id }),
      {
        headers: { 'Content-Type': 'application/json' }
      }, { 'Access-Control-Allow-Credentials': true }).then(res => {
        if (res.status == 200) {
          setIsCleanToday(res?.data)
          console.log("is clean ? " + res?.data)
        }
      }).catch(err => {
        setIsCleanToday(false)
        console.log("false clean today")
      })
  }


  const getExploit = async () => {
    await axios.post(`/site_solar/exploit_site`,
      JSON.stringify({ siteId: id }),
      {
        headers: { 'Content-Type': 'application/json' }
      }, { 'Access-Control-Allow-Credentials': true }).then(res => {
        setExploit(res?.data?.exploit)
        console.log("exploit" + res?.data?.exploit)
      }).catch(err => {
        setExploit(0)
        console.log(err?.data)
      })
    setIsLoading(false)
  }


  const expected_annual_loss = async () => {
    await axios.post(`/site_solar/expected_annual_loss`,
      JSON.stringify({ siteId: id }),
      {
        headers: { 'Content-Type': 'application/json' }
      }, { 'Access-Control-Allow-Credentials': true }).then(res => {
       
        setLostyear(res?.data? res?.data : 0)
      }).catch(err => {
        setLostyear(0)
    
      })
    // setIsLoading(false)    
  }









  // const a=(x)=>{return (x===sum1) ? "" : setSum1(sum) }
  useEffect(() => {
    setIsLoadingChart(true)
    setIndexSelected(-1)
    if (action === SITE_INTERVAL_THRITY_DAYS_URL) {
      setUnit('KWh')
      setForamteX('DD MMM')
      setForamtData('DD MMM YYYY ')
      setSum(0)
      setwidthpx('5px')
    }
    else {
      if (action === SITE_INTERVAL_TWELVE_MONTHS_URL) {
        setUnit('MWh')
        setForamteX('MMM')
        setForamtData('MMM YYYY ')
        setSum(0)
        setwidthpx('11px')
      }
      else {
        if (action === SITE_INTERVAL_ALL_YEARS_URL) {
          setUnit('MWh')
          setForamteX('YYYY')
          setForamtData('YYYY ')
          setSum(0)
          setwidthpx('20px')
        }
      }
    }
    IsCheckContactSiteWithUserId()


  }, [action])



  const IsCheckContactSiteWithUserId = async () => {
    await axios.get(SITE_URL,
      {
        headers: { 'Content-Type': 'application/json' }
      },
      { 'Access-Control-Allow-Credentials': true }).then(res => {
        console.log(res)
        if (res?.status === 200) {
          getCleanToday()
          getExploit()
          getRate()
          setSite(res.data)
          //more Request: 
          getIntervalSolarEnergy(res.data)
          expected_annual_loss()
          setTimeout(() => {
            setIsLoading(false)
            setIsLoadingChart(false)
          }, 430)
      

        }
        else{
          if (res?.response?.status === 502)
        {
          navigate(`/site_setting/${id}`, { replace: true })
        }
        else {
          setSite_id(null)
          navigate('/', { replace: true })
        }
      }
      })
      .catch(err => {
        setSite_id(null)
        console.log("err")
        navigate('/', { replace: true })
      })
  }

  const getIntervalSolarEnergy = async (sitex) => {
    await axios.post(action,
      JSON.stringify({ siteId: id, date: dateChooiesString }),
      {
        headers: { 'Content-Type': 'application/json' }
      }, { 'Access-Control-Allow-Credentials': true }).then(res => {
        var tempArray = res?.data;
        // Todo : convert energey to chart: 
        var arryForamt = [];


        if (action === SITE_INTERVAL_THRITY_DAYS_URL) {
          var x = 0;
          tempArray.forEach((interval, index) => {
            var parseDate = format(parse(interval.date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd.MM')
            x = x + (interval.value / 1000)
            arryForamt = [...arryForamt, { 'x': parseDate, 'y': interval.value ? (interval.value / 1000.0) : 0 }] //""
          })
          setSum(x)
          // a(x) 
          setSum1(x)

        }
        else {
          if (action === SITE_INTERVAL_TWELVE_MONTHS_URL) {
            var x = 0;
            tempArray.forEach((interval, index) => {
              var parseDate = format(parse(interval.date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'MMM')
              x = x + (interval.value / 1000)
              arryForamt = [...arryForamt, { 'x': (parseDate), 'y': interval.value ? (interval.value / 1000000) : 0 }]
            })
            setSum(x)
          }
          else {
            if (action === SITE_INTERVAL_ALL_YEARS_URL) {
              var x = 0;
              tempArray.forEach((interval, index) => {
                var parseDate = format(parse(interval.date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'yyyy')
                x = x + (interval.value / 1000)
                arryForamt = [...arryForamt, { 'x': (parseDate), 'y': interval.value ? (interval.value / 1000000.0) : 0 }]
              })
              setSum(x)
            }
          }
        }
        setIntervalEnergy(arryForamt)
      })
  }



  return (

    isLoading ? <div className='load_prime'><GridLoader size="38" color='#37C25F' /></div> :
      <div style={{ minHeight: "100lvh", paddingBottom:"10px"}} className='container-fluid bg-dark' >


        <div style={{ width: "100%", height: "100%" }}>
          <HeaderNav siteid={id} />
          <div className='container-fluid  text-white' style={{ width: "100%", height: "100%" }}>
            <Rect_green />
            <Rect_green />

            {/* <Map/> */}
            <div className='text-header' style={{ width: "100%", height: "100%" }}>
              תפוקת המערכת
              {/* <HiOutlineUser className='glassmorphismCircleIcon' color='white' fontSize={'32px'}></HiOutlineUser>
     <AiOutlineSetting className='glassmorphismCircleIcon' color='white' fontSize={'32px'}></AiOutlineSetting>   */}
            </div>


            {/* {
        site &&
        <div className ='glassmorphism'  style={{'position': 'fixed','marginTop':'10px' , 'paddingTop' : '10px'}}>
          
          <h4>{site.name}</h4> 
          <p>
                    <b>Peak Power:</b> {site.peakPower}<br/>
                    <b>Location Power: </b>{`${site.location?.country}, ${site.location?.city}  ${site.location?.address}`} */}

            {/* <b>Last Update Time:</b> {site.lastUpdateTime}<br/>
                    <b>installation Date:</b> {site.installationDate}<br/> */}
            {/* <b>facturer Name:</b> {site.primaryModule?.manufacturerName}<br/>
                    <b>model Name:</b> {site.primaryModule?.modelName}<br/> 
                    <b>maximum Power:</b> {site.primaryModule?.maximumPower ? site.primaryModule?.maximumPower  : 0}<br/> 
                    <b>temperature Coef:</b> {site.primaryModule?.temperatureCoef? site.primaryModule?.temperatureCoef :0}<br/> 
                    <b>alert Quantity:</b> {site.alertQuantity}<br/>  */}
            {/* <h5 className='siteBody' style={{color: site.status === 'Active'? 'green' :"red" }}> 
              {site.status}
          </h5> */}
            {/* </p>
        </div> 
      } */}
            {/* intervalEnergy?.length > 0? */}

            <ButtonsGroupRenew action={action}
              setAction={setAction}
              setDateChooiesString={setDateChooiesString}
            ></ButtonsGroupRenew>

            {1 > 0 ?
              isLoadingChart ? <div className='load_charts'><GridLoader color='#37C25F' /></div>
                : <div> <IntervalSite intervalEnergy={intervalEnergy}
                  title=""
                  action={action}
                  sum={sum}
                  unit={unit}
                  foramteX={foramteX}
                  foramtData={foramtData}
                  widthpx={widthpx}
                  indexSelect={indexSelect} setIndexSelected={setIndexSelected}

                ></IntervalSite>  </div>
              : <b className='site'> No data energy show on chart  </b>}
            {/* {action === SITE_INTERVAL_HOURLY_URL && 
       <DataPickerPop setDateChooiesString ={setDateChooiesString} action = {action}></DataPickerPop> } */}
            {/* <div className ='glassmorphism'  style={{'marginTop':'10px' , 'paddingTop' : '10px'}}>
         
         </div> */}
            <CleanSys sum_month={Math.round(sum1 * (rate))} exploit={exploit < 100 ? exploit : 100} ClickCleanToday={ClickCleanToday} isCleanToday={isCleanToday} lostyear={lostyear}></CleanSys>

          </div>
        </div>
      </div>
  )
}

export default SiteInfoRenew
// intervalEnergy = {intervalEnergy}