import { useContext, useEffect, useState, useRef } from "react";
// import  {useNavigate}  from "react-router-dom";
import { Link, useNavigate } from 'react-router-dom'
import AuthContext from "../context/AuthProvider"
import FeedSites from "./FeedSites";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import axios from "axios";
import GridLoader from "react-spinners/GridLoader";
// import { FaWhatsapp } from "react-icons/fa";
import whatsapp from "../components/whatsapp.png"
const Home = () => {

  const { auth, setAuth, logout, site_id, setSite_id } = useContext(AuthContext);
  const [sites, setSites] = useState([]);
  // const [solarName , setSolarName] = useState('') 
  const [solarId, setSolarId] = useState(0)
  const [privateKey, setPrivateKey] = useState('')
  const [success, setSuccess] = useState('')
  const [errMsg, setErrMsg] = useState('');
  const [state, setState] = useState('');

  const [valueOption, setValueOption] = useState('solaredge')
  const [isOpenBelongSite, setIsOpenBelongSite] = useState(new Boolean())
  const [isLoading, SetIsLoading] = useState(true)

  const navigate = useNavigate();
  const userRef = useRef()

  useEffect(() => {
    //  if(!auth)
    //  {
    //   logoutFun()
    //  }

    userRef?.current?.focus();
    setIsOpenBelongSite(false)
  }, [])

  useEffect(() => {
    setSuccess('')
    setErrMsg('')
  }, [solarId])

  const FuninfoSite = () => {

    if (auth) {
      if (site_id != null) {
        navigate(`site/${site_id}`)
      }
      else {
        axios.get('ownersite/find_all_belong').then((res) => {
          const resT = res.data;
          setSites(resT)
          setTimeout(() => {
            SetIsLoading(false)
          }, 180);
        }).catch((err) => {
          setErrMsg(err)
          if (err.response?.status === 401) {
            logoutFun()
          }
        }
        )
      }
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    await (state=="404"? axios.post('ownersite/add_solar_private',
      JSON.stringify({ siteId: solarId, private_key: privateKey ,company: valueOption })
      ,
      {
        headers: { 'Content-Type': 'application/json' }
      },
      { 'Access-Control-Allow-Credentials': true })
      
      :

      axios.post('ownersite/add_belong',
      JSON.stringify({ siteId: solarId, company: valueOption })
      ,
      {
        headers: { 'Content-Type': 'application/json' }
      },
      { 'Access-Control-Allow-Credentials': true })
      
      ).then((res) => {
        if (res?.status === 200) {
          setErrMsg('')
          setSuccess(res?.data)
          FuninfoSite()
          setState("0")
          setPrivateKey("")
        }
        else {
         
          // if (res.response?.status === 401) {
            // setSuccess('')
            // setErrMsg(res.response?.data)
          //   logoutFun()
          // }
          if (res?.response?.status === 404) {
            console.log("404")
            setSuccess('')
            setErrMsg(res?.response?.data)
            setState("404")
          }
          if (state=="404"&& res?.response?.status === 500) {
            console.log("404500")
            setErrMsg(" המפתח או מספר המערכת לא תקין")
            setSuccess('')
            setState("404")

          }
          else if(res?.response?.status === 500) {
            console.log("500")
            setErrMsg(res?.response?.data)
            setSuccess('')
            setState("500")

          }
        }
      })
      .catch((err) => {
        console.log("Loger:" + err)
        setSuccess('')
        setErrMsg(err.response?.data)
        if (err.response?.status === 401) {
          logoutFun()
        }
      })
  }

  useEffect(() => {
    FuninfoSite()
  }, [])


  const logoutFun = async () => {
    setAuth(null)
    logout()
    navigate('/login')
  }

  const handelToggelBelongeSite = () => {
    setIsOpenBelongSite(!isOpenBelongSite)
  }




  return (
    <div>
      {isLoading ? (<div className='load_prime'><GridLoader size="38" color='#37C25F' /></div>) :
        (<main style={{ display: (!isLoading) ? "" : "none" }} className='container-fluid bg-dark text-white mt-4'>
          <Link className="nav-link text-light" to="/login" onClick={() => logoutFun()} ><b className='li_text'>התנתקות</b></Link>
          {sites?.length > 0 ? (<FeedSites style={{ flex: 1 }} sits={sites} FuninfoSite={FuninfoSite} setSuccess={setSuccess} setErrMsg={setErrMsg} ></FeedSites>) :
            (<h5 className='bg-dark text-warning'> <strong>Solar Systems not connected</strong></h5>)
          }
          {success ? <p className="alert alert-success mt-2 mb-1"><b>{success}</b></p> : null}

          {errMsg ? <p className="alert alert-danger mt-2 mb-1"><b>{errMsg}</b></p> : null}

          {((isOpenBelongSite != null) && isOpenBelongSite) ? (<AiOutlineMinusCircle className="mt-3" fontSize={'38px'} onClick={handelToggelBelongeSite} />) :
            (<AiOutlinePlusCircle className="mt-3" fontSize={'38px'} onClick={handelToggelBelongeSite} />)}

          {((isOpenBelongSite != null) && isOpenBelongSite) ?
            (
              <div className='glassmorphism mt-2'>
                <form className="bg-light border border-secondary rounded" onSubmit={handleSubmit}>
                  {/* <div> 
            <label className="form-label text-dark"  htmlFor="solar_name" ><b>Solar Name</b></label>
            <input className="form-control"
                  style={{ 'width': '70%' , 'maxWidth':'700px' }}
                  id="solar_name"
                  ref={userRef}
                  autoComplete = "off"
                  value =  {solarName}
                  onChange = {(e)=> setSolarName(e.target.value)}
                  required
                  placeholder="Solar name"
                  type = 'text'
                  ></input>
          </div>  */}

                  <div>
                    <label className="form-label text-dark" htmlFor="solar_id"><b>Solar Id</b></label>
                    <input className="form-control"
                      style={{ 'width': '50%', 'maxWidth': '700px' }}
                      id="solar_id"
                      autoComplete="off"
                      value={solarId}
                      onChange={(e) => setSolarId(e.target.value)}
                      required
                      placeholder="System ID "
                      type="number"
                      min={1}
                      ref={userRef}
                      // onBlur={checkIf}
                    ></input>

                    {
                      (state=="404")&&<div>
                        <div className="m-2">
                            <a href="https://api.whatsapp.com/send?phone=972526269866" target="_blank" style={{display:"flex" , justifyContent:"center" }}>
                            <img src={whatsapp} alt="ads" style={{width:"30px"}} />
                              
                              <p className="text-dark m-2">
                                לבקשת מפתחות של המערכת מסולארדג הנה לחצו כאן
                              </p>
                              {/* <i class="fa fa-whatsapp whatsapp-icon"></i> */}
                              {/* <FaWhatsapp/> */}
                            </a>
                        </div>




                    <label className="form-label text-dark" htmlFor="Api_key"><b>Api_key</b></label>
                    <input className="form-control"
                      style={{ 'width': '50%', 'maxWidth': '700px' }}
                      id="solar_id"
                      autoComplete="off"
                      value={privateKey}
                      onChange={(e) => setPrivateKey(e.target.value)}
                      required
                      placeholder="insert Api key"
                      type=""
                      min={1}
                      // ref={userRef}
                      // onBlur={checkIf}
                    ></input>
                    </div>}



                  </div>
                  <div>
                    <label className="form-label text-dark"><b>
                      Company
                      {/* חברה */}
                    </b></label>
                    <select
                      className="form-control"
                      style={{ 'width': '50%', 'maxWidth': '700px' }}
                      value={valueOption}
                      onChange={(e) => setValueOption(e.target.value)}>
                      <option value="solaredge">Solaredge</option>
                      <option value="another">Another</option>
                    </select>
                  </div>
                  {/* <div style={{ display: "flex", flex: "row", justifyContent: "center", alignItems: "center", margin: 10 }}>

                    <label className="text-dark form-label " style={{ direction: "rtl" }}>אני מאשר/ת לגרין ברש לפנות בשמי לסולראדג , לקבלת Api Key של המערכת</label>
                    <input type="checkbox" style={{ width: 30, height: 30, margin: 5 }} required></input>
                  </div> */}

                  {/* <div className="form-text" >We'll never share your site info with anyone else.</div> */}
                  {/* <div className="form-text mt-2 mb-2" >Do you need to allow access site for us?
          <a className="link-primary" target="_blank" rel="noreferrer" href="https://www.solaredge.com/us/site-transfer-request" title="solaredge transfer"> click here</a>
          . if you did it you don't need it again</div> */}
                  <div className="mt-4">
                    <button className="btn btn-primary mb-2 border border-secondary"
                      type="submit"
                      style={{ 'width': '80%', 'maxWidth': '700px' }}
                    >
                      Add Solar
                      {/* הוסף סולאר */}
                    </button>
                  </div>
                </form>
              </div>) : null
          }

        </main>)}
    </div>
  )

}

export default Home

